import Vue from "vue";
import App from "./App.vue";
import { router } from "./route";
import store from "./store";

// import "@/oauthfilter";
import pinyin from "js-pinyin";
// 条形码
import VueBarcode from "@xkeshi/vue-barcode";
// 表格
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'

/*控件引用*/
import { NavBar } from "vant"; //导航栏
import { List } from "vant"; //列表
import { Cell } from "vant"; //表单
import { Search } from "vant"; //搜索
import { IndexBar, IndexAnchor } from "vant"; //标题索引
import { DropdownMenu, DropdownItem } from "vant"; //下拉菜单
import { Popup } from "vant"; //弹出层
import { DatetimePicker } from "vant"; //时间控件
import { Button } from "vant"; //按钮控件
import { Icon } from "vant"; //图标控件
import { Image } from "vant"; //图片控件
import { Field } from "vant"; //输入框控件
import { Dialog } from "vant"; //弹出框
import { Checkbox, CheckboxGroup } from "vant"; //复选框
import { SwipeCell } from "vant"; //滑动单元格
import { Calendar } from "vant"; //日历
import VueAMap from "vue-amap"; //地图
import VueQr from "vue-qr"; //引入生成二维码插件
import { CellGroup, Toast } from "vant";
import Element from "element-ui";
import { Switch } from "vant"; //Switch开关
import { Panel } from "vant";
import { Tab, Tabs } from "vant";
import { Grid, GridItem } from "vant";
import { Overlay } from "vant";
import { Skeleton } from "vant"; //骨架屏
import { Tag } from "vant"; //Tag标记
import { Lazyload } from "vant"; //懒加载
import { NoticeBar } from "vant"; //通知栏
import { Swipe, SwipeItem } from "vant";
import { TreeSelect } from "vant";
import { Card } from "vant"; //卡片
import { Row, Col } from "vant"; //Layout布局
import { Uploader } from 'vant';//文件上传
import { Collapse, CollapseItem } from 'vant';//折叠面板
import { Form } from 'vant';//折叠面板
import { Cascader } from 'vant';//地址选择器
import { Picker } from 'vant';//弹出选择
import { RadioGroup, Radio } from 'vant';//单选框
import { SubmitBar } from 'vant';//订单提交
import { Tabbar, TabbarItem } from 'vant';//标签页


Vue.use(TreeSelect);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
Vue.use(Lazyload, { lazyComponent: true });
Vue.use(Tag);
Vue.use(Skeleton);
Vue.use(Overlay);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Panel);
Vue.use(Switch);
Vue.use(CellGroup).use(Toast);
Vue.use(Element);
Vue.use(VueQr);
Vue.use(VueAMap);
Vue.use(Calendar);
Vue.use(Dialog);
Vue.use(Field);
Vue.use(Image);
Vue.use(Icon);
Vue.use(Button);
Vue.use(DatetimePicker);
Vue.use(Popup);
Vue.use(DropdownMenu).use(DropdownItem);
Vue.use(IndexBar).use(IndexAnchor);
Vue.use(Cell);
Vue.use(NavBar);
Vue.use(List);
Vue.use(Search);
Vue.use(SwipeCell);
Vue.use(Checkbox).use(CheckboxGroup);
Vue.use(pinyin);
Vue.use(Card);
Vue.use(Row).use(Col);
Vue.use(Uploader);
Vue.use(Collapse).use(CollapseItem);
Vue.use(Form);
Vue.use(Cascader);
Vue.use(Picker);
Vue.use(VXETable);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(SubmitBar);
Vue.use(Tabbar);
Vue.use(TabbarItem);


/*样式引用*/
import "@/assets/css/inline_style_all.css"; //主样式
import "@/assets/css/calendar_index.css"; //日历样式
import "@/assets/iconfont/switch/iconfont.css"; //切换
import "@/assets/iconfont/sort/iconfont.css"; //排序(降序)
import "@/assets/iconfont/sorts/iconfont.css"; //排序(升序)

/* jQuery引用 */
//import "../static/js/jquery-2.2.2.min.js";

// 引用条形码组件库
Vue.component("barcode", VueBarcode);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
