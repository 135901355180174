import Vue from "vue";
import Router from "vue-router";
import Container from "@/components/Container.vue";

Vue.use(Router);

const routes = [
  {
    path: "/",
    component: Container,
    children: [
      {
        path: "/",
        component: () => import("./view/index/naigation.vue"),
        children: [
          {
            name: "/",
            path: "/",
            component: () => import("./view/TinySite/Home.vue"),
            meta: { title: "我的服务" },
          },
          {
            name: "home",
            path: "/home",
            component: () => import("./view/TinySite/Home.vue"),
            meta: { title: "我的服务" },
          },
          {
            name: "ReceiveL",
            path: "/ReceiveL",
            component: () => import("./view/TinySite/ReceiveMessageList.vue"),
            meta: { title: "已收消息列表" },
          },
          {
            name: "LinkM",
            path: "/LinkM",
            component: () => import("./view/Mine/LinkMan.vue"),
            meta: { title: "通讯录" },
          },
          {
            name: "me",
            path: "/me",
            component: () => import("./view/index/me.vue"),
            meta: { title: "我的" },
          },
        ]
      },
      // {
      //   name: "/",
      //   path: "/home",
      //   component: () => import("./view/TinySite/Home.vue"),
      //   meta: { title: "我的服务" },
      // },
      // {
      //   path: "/",
      //   name: "index",
      //   meta: { title: "正在跳转" },
      //   component: () => import("./view/user"),
      // },
      // {
      //   path: "user",
      //   component: Container,
      //   children: [
      //     {
      //       path: "/",
      //       name: "userIndex",
      //       meta: { title: "用户中心" },
      //       component: () => import("./view/user"),
      //     },
      //     {
      //       path: "profile",
      //       name: "profile",
      //       meta: { title: "用户资料" },
      //       component: () => import("./view/user/profile"),
      //     },
      //   ],
      // },
      // {
      //   name: "oauth",
      //   path: "/oauth",
      //   component: () => import("./view/oauth"),
      //   meta: { title: "微信授权" },
      // },
      // {
      //   name: "bind",
      //   path: "/bind",
      //   component: () => import("./view/bind"),
      //   meta: { title: "注册信息" },
      // },
      // {
      //   name: "UA",
      //   path: "/UA",
      //   component: () => import("./components/Manager/UserAgree.vue"),
      //   meta: { title: "用户协议" },
      // },

      // /**
      //  * 成员管理
      //  */
      // {
      //   name: "ulist",
      //   path: "/ulist",
      //   component: () => import("./components/Manager/CardManage.vue"),
      //   meta: { title: "成员管理" },
      // },

      /**
      * 校园互动
      */


      {
        name: "SendList",
        path: "/SendList",
        component: () => import("./view/TinySite/SendMessageList.vue"),
        meta: { title: "已发消息列表" },
      },
      {
        name: "SendM",
        path: "/SendM",
        component: () => import("./view/TinySite/SendMessage.vue"),
        meta: { title: "已发消息详情" },
      },

      {
        name: "ReceiveM",
        path: "/ReceiveM",
        component: () => import("./view/TinySite/ReceiveMessage.vue"),
        meta: { title: "已收消息详情" },
      },
      {
        name: "NewL",
        path: "/NewL",
        component: () => import("./view/TinySite/NewMessageList.vue"),
        meta: { title: "新建消息列表" },
      },
      {
        name: "NewM",
        path: "/NewM",
        component: () => import("./view/TinySite/NewMessage.vue"),
        meta: { title: "新建消息" },
      },

      /**
      * 个人中心
      */
      {
        name: "Mine",
        path: "/Mine",
        component: () => import("./view/Mine/Mine.vue"),
        meta: { title: "我的账户" },
      },
      {
        name: "StuM",
        path: "/StuM",
        component: () => import("./view/Mine/StudendMore.vue"),
        meta: { title: "学生信息" },
      },
      {
        name: "parM",
        path: "/parM",
        component: () => import("./view/Mine/ParentsMore.vue"),
        meta: { title: "家长信息" },
      },
      {
        name: "StuL",
        path: "/StuL",
        component: () => import("./view/Mine/StudendList.vue"),
        meta: { title: "请选择操作对象" },
      },
      {
        name: "Pay",
        path: "/Pay",
        component: () => import("./view/Mine/Pay.vue"),
        meta: { title: "选择充值金额" },
      },
      {
        name: "ClickLink",
        path: "/ClickLink",
        component: () => import("./view/Mine/ClickLink.vue"),
        meta: { title: "通讯录" },
      },
      {
        name: "ClickG",
        path: "/ClickG",
        component: () => import("./view/Mine/ClickGrade.vue"),
        meta: { title: "成绩单" },
      },
      {
        name: "Grade",
        path: "/Grade",
        component: () => import("./view/Mine/Grade.vue"),
        meta: { title: "成绩单" },
      },
      // {
      //   name: "ClickAtt",
      //   path: "/ClickAtt",
      //   component: () => import("./components/Mine/ClickAttendance.vue"),
      //   meta: { title: "考勤统计" },
      // },
      // {
      //   name: "Attendance",
      //   path: "/Attendance",
      //   component: () => import("./components/Mine/Attendance.vue"),
      //   meta: { title: "考勤统计" },
      // },
      {
        name: "ClickTrack",
        path: "/ClickTrack",
        component: () => import("./view/Mine/ClickTrack.vue"),
        meta: { title: "考勤记录" },
      },
      {
        name: "Track",
        path: "/Track",
        component: () => import("./view/Mine/Track.vue"),
        meta: { title: "考勤记录" },
      },
      // {
      //   name: "ClickClassSch",
      //   path: "/ClickClassSch",
      //   component: () => import("./components/Mine/ClickClassSch.vue"),
      //   meta: { title: "课程表" },
      // },
      // {
      //   name: "ClassSchedule",
      //   path: "/ClassSchedule",
      //   component: () => import("./components/Mine/ClassSchedule.vue"),
      //   meta: { title: "课程表" },
      // },
      {
        name: "ClickAstrict",
        path: "/ClickAstrict",
        component: () => import("./view/Mine/ClickAstrict.vue"),
        meta: { title: "消费限制设置" },
      },
      {
        name: "Astrict",
        path: "/Astrict",
        component: () => import("./view/Mine/Astrict.vue"),
        meta: { title: "消费限制设置" },
      },
      {
        name: "inform",
        path: "/inform",
        component: () => import("./view/Mine/inform.vue"),
        meta: { title: "消息通知设置" },
      },
      {
        name: "Help",
        path: "/Help",
        component: () => import("./view/Mine/Help.vue"),
        meta: { title: "帮助" },
      },
      {
        name: "ClickMoney",
        path: "/ClickMoney",
        component: () => import("./view/Mine/ClickMoney.vue"),
        meta: { title: "消费记录" },
      },
      {
        name: "Money",
        path: "/Money",
        component: () => import("./view/Mine/Money.vue"),
        meta: { title: "消费记录" },
      },
      {
        name: "Particulars",
        path: "/Particulars",
        component: () => import("./view/Mine/Particulars.vue"),
        meta: { title: "详情" },
      },
      {
        name: "ClickMney",
        path: "/ClickMney",
        component: () => import("./view/index/ClickMney.vue"),
        meta: { title: "选择充值对象" },
      },
      {
        name: "Mney",
        path: "/Mney",
        component: () => import("./view/index/Mney.vue"),
        meta: { title: "充值" },
      },
      /**
       * 帮助页面
       */
      {
        name: "HBind",
        path: "/HBind",
        component: () => import("./view/Help/HBind.vue"),
        meta: { title: "帮助" },
      },
      {
        name: "HRece",
        path: "/HRece",
        component: () => import("./view/Help/HRece.vue"),
        meta: { title: "帮助" },
      },
      {
        name: "HTrack",
        path: "/HTrack",
        component: () => import("./view/Help/HTrack.vue"),
        meta: { title: "帮助" },
      },
      {
        name: "HPer",
        path: "/HPer",
        component: () => import("./view/Help/HPer.vue"),
        meta: { title: "帮助" },
      },
      {
        name: "HRef",
        path: "/HRef",
        component: () => import("./view/Help/HRef.vue"),
        meta: { title: "帮助" },
      },
      /**
       * 错误页面
       */
      {
        name: "error",
        path: "/error",
        component: Container,
        children: [
          {
            path: "/404",
            name: "404",
            meta: { title: "404错误，找不到页面" },
            component: () => import("./view/error/404.vue"),
          },
          {
            path: "/500",
            name: "500",
            meta: { title: "500错误，服务器异常" },
            component: () => import("./view/error/500.vue"),
          },
        ],
      },
    ],
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
