import axios from 'axios';
import appconst from './appconst';
import { Dialog } from 'vant';
const ajax = axios.create({
    baseURL: appconst.remoteServiceBaseUrl,
    timeout: 90000
});
ajax.interceptors.request.use(function (config) {
    if(window.abp.auth.getToken()){
        config.headers.common["Authorization"]="Bearer "+window.abp.auth.getToken();
    }
    config.headers.common[".AspNetCore.Culture"]=window.abp.utils.getCookieValue("Abp.Localization.CultureName");
    config.headers.common["Abp.TenantId"]=window.abp.multiTenancy.getTenantIdCookie();
    return config;
  }, function (error) {
    
    return Promise.reject(error);
});
function LoginException(message) {
    this.message = message;
    this.name = "LoginException";
}
ajax.interceptors.response.use((respon)=>{    
    return respon;
},(error)=>{
    if(!!error.response&&!!error.response.data.error&&!!error.response.data.error.message&&error.response.data.error.details){
        Dialog.alert({title: '出错了', message: error.response.data.error.details});
    }else if(!!error.response&&!!error.response.data.error&&!!error.response.data.error.message){
        //Dialog.alert({title: '登录失败', message: error.response.data.error.message});
        throw new LoginException(error.response.data.error.message);
    }else if(!error.response){
        Dialog.alert({title: '出错了', message: '系统发生未知错误，请稍后再试或与管理员取得联系'});
    }

    return Promise.reject(error);
})
export default ajax;