const AppConsts= {
    userManagement:{
        defaultAdminUserName: 'admin'
    },
    localization:{
        // defaultLocalizationSourceName: 'FuGongApi'
        // defaultLocalizationSourceName: 'JiangChengApi'
        // defaultLocalizationSourceName: 'ZhenYuanApi'
        defaultLocalizationSourceName: 'MouDingApi'
    },
    authorization:{
        encrptedAuthTokenName: 'enc_auth_token'
    },
    // appBaseUrl: "http://wx.fgxrmyy.cn/",
    // remoteServiceBaseUrl:"https://api.fgxrmyy.cn/",
    // thirdApiCenterBaseUrl:"http://oauth.fgxrmyy.cn/",
    // wechatAppId:"wxab583b5b776e236b"
    // appBaseUrl: "https://wx.jcxrmyy.top/",
    // remoteServiceBaseUrl:"https://api.jcxrmyy.top/",
    // thirdApiCenterBaseUrl:"https://oauth.jcxrmyy.top/",
    // wechatAppId:"wx40a97371ed7f7a55"
    appBaseUrl: "https://wx.lcxdyrmyy.cn/",
    remoteServiceBaseUrl:"https://api.lcxdyrmyy.cn/",
    thirdApiCenterBaseUrl:"https://oauth.lcxdyrmyy.cn/",
    wechatAppId:"wxcd5fb87762015eac"
    //wxcd5fb87762015eac 澜沧县微信appid
    // appBaseUrl: "http://192.168.101.101:8090",
    // remoteServiceBaseUrl:"http://192.168.101.101:8090",
    // thirdApiCenterBaseUrl:"https://oauth.mdxrmyy.top",
    // wechatAppId:"wx0f2c746b8f9dd1e8"
    // appBaseUrl: "http://wx.peszyxrmyy.com/",
    // remoteServiceBaseUrl:"http://api.peszyxrmyy.com/",
    // thirdApiCenterBaseUrl:"http://oauth.peszyxrmyy.com/",
    // wechatAppId:"wxb504284bb6522503"
    // appBaseUrl: "http://localhost:8080/",
    // remoteServiceBaseUrl: "http://192.168.101.101:9001/",
    // thirdApiCenterBaseUrl: "http://apitest.ceres.pub/",
    // wechatAppId: "wxfbc4a7004efe6635"
}
export default AppConsts