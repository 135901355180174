const state = {
    userId:'',//用户Id
    depid:'',//科室id
    depname:'',//科室名称
    dorid:'',//医生id
    dorname:'',//医生名字
    result:'',//微信登录状态
    token:'',//登录状态
    userName:'',//就诊卡姓名
    idcard:'',//身份证号
    owneridcard:'',//身份证号
    phone:'',//电话号码
    regLevel:'',//挂号级别
    regLevelName:'',//挂号级别名称
    seeTime:'',//看诊时间
    beginTime:'',//看诊开始时间
    endTime:'',//看诊结束时间
    scheduleid:'',//号源
    patientid:'',//病历号
    cardNo:'',//就诊卡号
    totalFee:'',//挂号费
    regNo:'',//预约挂号流水号
    orderId:'',//支付订单号
    reportId:'',//报告编码
    inpatientSeriNo:'',//住院流水号
    inpatientNo:'',//住院号
    seqNo:'',//处方序号
    noonId:'',//挂号午别
    payArg:'',
    seeNo:'',//号源编号
    schemaNO:'',
    schemaId:'',//排班流水号
    deptCode:'',
    doctCode:''
};

const getters = {
    userId:(state) => state.userId,
    depid: (state) => state.depid,
    depname: (state) => state.depname,
    dorname: (state) => state.dorname,
    dorid: (state) => state.dorid,
    result: (state) => state.result,
    token: (state) => state.token,
    userName: (state) => state.userName,
    idcard: (state) => state.idcard,
    owneridcard: (state) => state.owneridcard,
    phone: (state) => state.phone,
    regLevel: (state) => state.regLevel,
    regLevelName: (state) => state.regLevelName,
    seeTime: (state) => state.seeTime,
    beginTime: (state) => state.beginTime,
    endTime: (state) => state.endTime,
    scheduleid: (state) => state.scheduleid,
    patientid: (state) => state.patientid,
    cardNo: (state) => state.cardNo,
    totalFee: (state) => state.totalFee,
    regNo: (state) => state.regNo,
    orderId: (state) => state.orderId,
    reportId: (state) => state.reportId,
    inpatientSeriNo: (state) => state.inpatientSeriNo,
    inpatientNo: (state) => state.inpatientNo,
    seqNo: (state) => state.seqNo,
    noonId: (state) => state.noonId,
    payArg: (state) => state.payArg,
    seeNo: (state) => state.seeNo,
    schemaNO: (state) => state.schemaNO,
    schemaId: (state) => state.schemaId,
    deptCode: (state) => state.deptCode,
    doctCode: (state) => state.doctCode,
};
  
const mutations = {
    setUserId(state,userId){
        state.userId=userId;
    },
    setDepid(state,depid){
        state.depid=depid;
    },
    setDorid(state,dorid){
        state.dorid=dorid;
    },
    setResult(state,result){
        state.result=result;
    },
    setToken(state,token){
        state.token=token;
    },
    setUserName(state,userName){
        state.userName = userName;
    },
    setIdcard(state,idcard){
        state.idcard = idcard;
    },
    setOwneridcard(state,owneridcard){
        state.owneridcard = owneridcard;
    },
    setPhone(state,phone){
        state.phone = phone;
    },
    setRegLevel(state,regLevel){
        state.regLevel = regLevel;
    },
    setRegLevelName(state,regLevelName){
        state.regLevelName = regLevelName;
    },
    setSeeTime(state,seeTime){
        state.seeTime = seeTime;
    },
    setBeginTime(state,beginTime){
        state.beginTime = beginTime;
    },
    setEndTime(state,endTime){
        state.endTime = endTime;
    },
    setScheduleid(state,scheduleid){
        state.scheduleid = scheduleid;
    },
    setPatientid(state,patientid){
        state.patientid = patientid;
    },
    setCardNo(state,cardNo){
        state.cardNo = cardNo;
    },
    setDepname(state,depname){
        state.depname = depname;
    },
    setDorname(state,dorname){
        state.dorname = dorname;
    },
    setTotalFee(state,totalFee){
        state.totalFee = totalFee;
    },
    setRegNo(state,regNo){
        state.regNo = regNo;
    },
    setOrderId(state,orderId){
        state.orderId = orderId;
    },
    setReportId(state,reportId){
        state.reportId = reportId;
    },
    setInpatientSeriNo(state,inpatientSeriNo){
        state.inpatientSeriNo = inpatientSeriNo;
    },
    setInpatientNo(state,inpatientNo){
        state.inpatientNo = inpatientNo;
    },
    setSeqNo(state,seqNo){
        state.seqNo = seqNo;
    },
    setNoonId(state,noonId){
        state.noonId = noonId;
    },
    setPayArg(state,payArg){
        state.payArg = payArg;
    },
    setSeeNo(state,seeNo){
        state.seeNo = seeNo;
    },
    setSchemaNO(state,schemaNO){
        state.schemaNO = schemaNO;
    },
    setSchemaId(state,schemaId){
        state.schemaId = schemaId;
    },
    setDeptCode(state,deptCode){
        state.deptCode = deptCode;
    },
    setDoctCode(state,doctCode){
        state.doctCode = doctCode;
    },
};

const action = {
    commitUserId:({commit},userId)=>commit('setUserId',userId),
    commitDepid:({commit},depid)=>commit('setDepid',depid),
    commitDorid:({commit},dorid)=>commit('setDorid',dorid),
    commitResult:({commit},result)=>commit('setResult',result),
    commitToken:({commit},token)=>commit('setToken',token),
    commitUserName:({commit},userName)=>commit('setUserName',userName),
    commitIdcard:({commit},idcard)=>commit('setIdcard',idcard),
    commitOwnerIdcard:({commit},owneridcard)=>commit('setOwneridcard',owneridcard),
    commitPhone:({commit},phone)=>commit('setPhone',phone),
    commitRegLevel:({commit},regLevel)=>commit('setRegLevel',regLevel),
    commitRegLevelName:({commit},regLevelName)=>commit('setRegLevelName',regLevelName),
    commitSeeTime:({commit},seeTime)=>commit('setSeeTime',seeTime),
    commitBeginTime:({commit},beginTime)=>commit('setBeginTime',beginTime),
    commitEndTime:({commit},endTime)=>commit('setEndTime',endTime),
    commitScheduleid:({commit},scheduleid)=>commit('setScheduleid',scheduleid),
    commitPatientid:({commit},patientid)=>commit('setPatientid',patientid),
    commitCardNo:({commit},cardNo)=>commit('setCardNo',cardNo),
    commitDepname:({commit},depname)=>commit('setDepname',depname),
    commitDorname:({commit},dorname)=>commit('setDorname',dorname),
    commitTotalFee:({commit},totalFee)=>commit('setTotalFee',totalFee),
    commitRegNo:({commit},regNo)=>commit('setRegNo',regNo),
    commitOrderId:({commit},orderId)=>commit('setOrderId',orderId),
    commitReportId:({commit},reportId)=>commit('setReportId',reportId),
    commitInpatientSeriNo:({commit},inpatientSeriNo)=>commit('setInpatientSeriNo',inpatientSeriNo),
    commitInpatientNo:({commit},inpatientNo)=>commit('setInpatientNo',inpatientNo),
    commitSeqNo:({commit},seqNo)=>commit('setSeqNo',seqNo),
    commitNoonId:({commit},noonId)=>commit('setNoonId',noonId),
    commitPayArg:({commit},payArg)=>commit('setPayArg',payArg),
    commitSeeNo:({commit},seeNo)=>commit('setSeeNo',seeNo),
    commitSchemaNO:({commit},schemaNO)=>commit('setSchemaNO',schemaNO),
    commitSchemaId:({commit},schemaId)=>commit('setSchemaId',schemaId),
    commitDeptCode:({commit},deptCode)=>commit('setDeptCode',deptCode),
    commitDoctCode:({commit},doctCode)=>commit('setDoctCode',doctCode),
}

export default {
    namespaced: true,
    state,
    getters,
    action,
    mutations
  };