import ajax from '../../lib/ajax';
import Util from '../../lib/util';
import appconst from '../../lib/appconst';
import { Toast } from "vant";

const state = {
    information: null, //用户信息
    authorization: ""
};

const getters = {
    logined: (state) => !!state.information, //已登录
    information: (state) => state.information,
    userName: (state) =>
      state.information
        ? state.information.user
          ? state.information.user.name
          : ""
        : "",
    phone: (state) =>
      state.information
        ? state.information.user
          ? state.information.user.userName
          : ""
        : "",
    id: (state) =>
      state.information
        ? state.information.user
          ? state.information.user.id
          : 0
        : 0
  };
  
  // actions
  const actions = {
    /**
     * 用户登录
     * @param {context} param0
     * @param {any} model
     */
    async login({ commit }, wxOpenId) {
      let authorization = "";
      if (typeof wxOpenId === "string") {
        await ajax.post("/api/TokenAuth/Authenticate", {
          userNameOrEmailAddress:"wechat",
          password:"TS2020lwh"
        })
        .then(async rep => {
          var tokenExpireDate = undefined;
          authorization = rep.data.result.accessToken;
          Util.abp.auth.setToken(rep.data.result.accessToken, tokenExpireDate);
          Util.abp.utils.setCookieValue(appconst.authorization.encrptedAuthTokenName, rep.data.result.encryptedAccessToken, tokenExpireDate, Util.abp.appPath);
        });
      } else {
        Toast.fail({ message: "不识别的登录凭证" });
        return;
      }

      await ajax.get("/api/services/app/Session/GetCurrentLoginInformations")
      .then(async rep => {
        const information = rep.data.result;
        commit("SAVE", { authorization, information });
      });
    },
  
    // remove token
    resetToken({ commit }) {
      commit("setAuthorization", "");
      localStorage.clear();
    }
  };
  
  // mutations
  const mutations = {
    SAVE(state, payload) {
      Object.assign(state, payload);
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  };